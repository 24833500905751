<template>
  <ResizableColumnContainer :maxCol="2" @total-size="onTotalSize">
    <resizable-column
      v-show="true"
      :key="componentKey"
      :className="`flex-container flex-col layout-content w10 innovativestudio_colonne infotronik_social_lista`"
      :columnIndex="0"
      @column-resize="campaignColumnResize"
      ref="list"
    >
      <QuotesList>
        :quotes="quotes" :selectedId="route.params.id ? route.params.id : ''"
      </QuotesList>
    </resizable-column>
  </ResizableColumnContainer>
</template>

<script>
import ResizableColumn from "@/components/ResizableColumn";
import ResizableColumnContainer from "@/components/ResizableColumnContainer";
import QuotesList from "@/components/quotes/QuotesList.vue";
import { onTotalSize, campaignColumnResize } from "@/utils/ColumnsResizer";

export default {
  name: "Quotes",
  // Qui dovrai recuperare i tuoi preventivi dallo store Vuex o da un'API
  components: {
    QuotesList,
    ResizableColumn,
    ResizableColumnContainer,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  setup() {
    // Logica per la creazione di un nuovo preventivo
    return {
      onTotalSize,
      campaignColumnResize,
    };
  },
};
</script>
