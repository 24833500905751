<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel">
        <div class="flex-container flex-col p24">
          <div class="panel-header">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">{{ title }}</h2>
          </div>
          <p v-html="formattedMessage"></p>
        </div>
        <div class="flex-container abs panel-footer w100">
          <button
            class="w100 deny noborder nopadding bebas"
            @click="this.$emit('deny-confirm', message)"
          >
            no
          </button>
          <button
            class="w100 noborder nopadding bebas"
            @click="this.$emit('allow-confirm', message)"
            :disabled="disableButtons"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  props: {
    title: String,
    message: String,
    confirmButtonText: {
      type: String,
      default: "Elimina",
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deny-confirm", "allow-confirm"],
  computed: {
    formattedMessage() {
      return this.message.replace(/\n/g, "<br>");
    },
  },
};
</script>
<style lang="postcss">
.overlay-panel button.deny {
  color: white;
  background-color: black;
}
</style>
